import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

export interface Props {
  address?: string
  email?: string
  googleMapsURL?: string
  languageCode: string
  longitude?: string
  latitude?: string
  phone?: string
  siteName?: string
  title?: string
}

export const Directions = memo(function Directions({
  address,
  email,
  googleMapsURL,
  languageCode,
  longitude,
  latitude,
  phone,
  siteName,
  title,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  const bounds = [] as any

  bounds.push([latitude, longitude])

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [84, 94],
        iconAnchor: [42, 94],
        html: `<img src="/map_marker.svg" alt="Agriturismo Mormoraia" width="84" height="94">`,
        className: 'main-icon',
      }),
    )
  }, [])

  return (
    <Container>
      <Wrapper>
        {title ? <Heading>{title}</Heading> : null}
        <Inner>
          <Left>
            {siteName ? <Title>{siteName}</Title> : null}
            {address ? (
              <Address dangerouslySetInnerHTML={{ __html: address }} />
            ) : null}
            {googleMapsURL ? (
              <StyledButton
                label={useVocabularyData('open-google-maps', languageCode)}
                URL={googleMapsURL}
                rel="noreferrer"
                target="_blank"
                variant="outline"
              />
            ) : null}
            {phone ? (
              <>
                <Label>{useVocabularyData('phone', languageCode)}</Label>
                <Info
                  aria-label="link"
                  href={'tel:' + phone}
                  rel="noopener"
                  target="_blank"
                  onClick={() => {
                    if (isMobile) {
                      typeof window.gtag !== 'undefined' &&
                        window.gtag('event', 'Click', {
                          event_category: 'Website',
                          event_label: 'Phone Number',
                        })
                    }
                  }}
                >
                  {phone}
                </Info>
              </>
            ) : null}
            {email ? (
              <>
                <Label>{useVocabularyData('email', languageCode)}</Label>
                <Info
                  aria-label="link"
                  href={'mailto:' + email}
                  rel="noopener"
                  target="_blank"
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  {email}
                </Info>
              </>
            ) : null}
          </Left>

          {typeof window !== undefined && latitude && longitude ? (
            <Map
              center={[Number(latitude), Number(longitude)]}
              dragging={L.Browser && L.Browser.mobile ? false : true}
              zoom={13}
              scrollWheelZoom={false}
              whenCreated={(mapInstance: any) => {
                map.current = mapInstance
                mapInstance.fitBounds(bounds)
                mapInstance.setZoom(13)
              }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              />
              <Marker
                icon={markerIcon}
                position={[Number(latitude), Number(longitude)]}
              />
            </Map>
          ) : null}
        </Inner>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  padding-top: 10rem;
`

const Wrapper = styled.div`
  max-width: 100rem;
  margin: 0 auto;
`

const Heading = styled.h1`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 5rem;
  line-height: 4.6875rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 2.8125rem;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;

  @media (max-width: 1199px) {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
`

const Left = styled.div`
  padding-left: 8.938vw;

  @media (max-width: 1199px) {
    padding: 0 1.5625rem;
  }
`

const Title = styled.h2`
  max-width: 23.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.125rem;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  line-height: 3.4375rem;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Address = styled.div`
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.8125rem;
`

const StyledButton = styled(Button)`
  margin: 1.4375rem 0 4.0625rem;

  @media (max-width: 1199px) {
    margin: 1.4375rem auto 3.0625rem;
  }
`

const Label = styled.div`
  margin-top: 1.625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-transform: uppercase;
`

const Info = styled.a`
  display: block;
  margin-top: 0.3125rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 1.875rem;
  line-height: 2rem;

  @media (max-width: 1199px) {
    font-size: 1.375rem;
    line-height: 1.066;
  }
`

const Map = styled(MapContainer)`
  width: 50%;
  height: 83.333vh;
  max-height: 46.875rem;
  aspect-ratio: 800/750;

  @media (max-width: 1199px) {
    width: 100%;
    max-height: 34.375rem;
    margin-top: 5rem;
  }

  @media (max-width: 768px) {
    max-height: 25rem;
  }

  .leaflet-pane {
    z-index: 1 !important;
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 1 !important;

    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;

      a {
        position: relative;
        width: 3.4375rem;
        height: 3.4375rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight1};
        border: 0;
        border-radius: 0% !important;
        line-height: 2.875rem;
        text-indent: -9999px;
        transition: 0.2s ease-out;

        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.neutralDark1};

            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.neutralLight1};
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark1};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.neutralDark1};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            opacity: 0.2;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }
`
