import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { rgba } from 'emotion-rgba'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  accept?: string
  disabled?: boolean
  id?: string
  name: string
  type?: string
  placeholder?: string
  error?: boolean
  isDirty?: boolean
  required?: boolean
  variant?: Variant
  value?: string | number
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Input = memo(
  forwardRef<HTMLInputElement, Props>(function Input(
    {
      accept,
      disabled,
      id,
      name,
      type,
      placeholder,
      error = false,
      isDirty = false,
      required,
      variant = 'text',
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        accept={accept}
        error={error}
        id={id}
        isDirty={isDirty}
        disabled={disabled}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        variant={variant}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    )
  }),
)

Input.displayName = 'Input'

const Container = styled.input<ContainerProps>`
  ${({ theme, error, variant }) => {
    return css`
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 100px ${theme.colors.variants.primaryDark}
          inset;
        -webkit-text-fill-color: ${theme.colors.variants.primaryLight};
      }

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      [type='number'] {
        -moz-appearance: textfield;
      }

      ${variant === 'submit'
        ? css`
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: max-content;
            margin: 3.125rem auto 0;
            padding: 0.5rem 3rem;
            background: ${theme.colors.variants.primaryDark};
            border: 0.0625rem solid ${theme.colors.variants.primaryDark};
            color: ${theme.colors.variants.neutralLight2};
            font-family: ${theme.fontFamily.paragraph};
            font-weight: 500;
            font-size: 0.9375rem;
            letter-spacing: 0.1em;
            line-height: 1.5625rem;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
            z-index: 2;

            &:hover {
              color: ${theme.colors.variants.primaryDark};
              background: transparent;
            }

            &:disabled {
              opacity: 0.4;
              cursor: not-allowed;
            }

            @media (max-width: 768px) {
              max-width: 100%;
              width: 100%;
            }
          `
        : css`
            width: 100%;
            height: 3.75rem;
            padding: 0 1.25rem;
            background: ${rgba(theme.colors.variants.neutralLight3, 0.5)};
            border-bottom: 0.0625rem solid
              ${error
                ? theme.colors.variants.danger
                : rgba(theme.colors.variants.neutralLight3, 0.5)};
            color: ${theme.colors.variants.primaryDark};
            font-family: ${theme.fontFamily.paragraph};
            font-weight: 300;
            font-size: 1rem;
            letter-spacing: 0.02em;

            &::-webkit-input-placeholder,
            &::-moz-placeholder,
            &::-ms-input-placeholder,
            &::placeholder {
              opacity: 0.7;
            }
          `}
    `
  }}
`

interface ContainerProps {
  error: boolean
  isDirty: boolean
  variant: Variant
}

export type Variant = 'text' | 'checkbox' | 'submit'
